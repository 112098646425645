body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url('assets/images/cursor/cursor.svg'), auto;
  background-color: black;
}
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.path{
  stroke-dasharray: 300px;
  stroke-dashoffset: 300px;
  animation: dash 2s 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

.remove_pattern_lg {
  display: block;
}

@media (max-width: 1300px) {
  .remove_pattern_lg{
    display: none;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0px;
  }
}

.squiggle {
  stroke-dasharray: 60px;
  stroke-dashoffset: 60px;
  animation: dash 2s ease-in;
  animation-fill-mode: forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0px;
  }
}


.swiper-slide {
  overflow: hidden;
  background-size: cover;
  background-position: center;
  transform-origin: center center;
}


.swiper-wrapper {
  transition-duration: 0ms !important;
  height: 100vh !important;
}

.swiper-pagination-vertical {
  bottom: 10px;
  left: 1vh !important;
  height: 100%;
  width: fit-content !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}



.swiper-pagination-bullet {
  width: 1vw !important;
  height: 1vw !important;
  border-radius: 0px !important;
  transform: rotate(45deg);
  margin: 1vh !important;
  border: 1px solid white;
  background-color: rgba(255,255,255,0.3) !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background-color: rgb(255, 28, 110, 0.3) !important;
  border: 2px solid #FF1C6E;
}

.swiper-fade .swiper-slide-active, .swiper-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: none
}

@media (max-width: 908px) {
  .swiper-pagination-bullet {
    display: none;
  }
  .swiper-pagination-bullet-active {
    display: none;
  }
  .swiper-pagination-vertical {
    display: none;
  }
}

.alice-carousel__stage-item {
  display: inline-flex;
  justify-content: center;
}

.alice-carousel__wrapper {
  width: 945px;
  height: 433px;
  display: flex;
  align-items: center;
}

@media (min-width: 1120px) {
  .alice-carousel__wrapper {
    padding-left: 12px !important;
  }
}

@media (max-width: 1120px) {
  .alice-carousel__wrapper {
    width: 625px;
    height: 250px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 1200px) {
  .alice-carousel__wrapper {
    width: calc(100vw - 120px);
  }
}

@media (max-width: 768px) {
  .alice-carousel__wrapper {
    width: 625px;
  }
}
.alice-carousel__stage-item {
  display: inline-flex;
  height: 100%;
  align-items: center;
}
.alice-carousel__stage-item.__active:nth-of-type(0) {
  transform: scale(2);
}
.alice-carousel__stage-item.__target {
  transform: scale(1);
}

.alice-carousel__stage-item.__cloned {
  transform: scale(1);
}