.q {
    width: 300px;
   
}
.alignCenter {
    height: 100vh;
    align-items:center;
    display:flex;

}

.bottomPadding {
    padding-bottom: 200px;
}

  .flexCol {
      display: flex;
      flex-direction: column;
  }

  .flexRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100vw;
      max-width: 1800px;
  }

  .paddingTop {
      padding-top: 20rem;
  }

  @media (max-width: 1366px) {
    .alignCenter {
        align-items: baseline;
    }
    
    .header, .flexRow {
        padding-left: 5%;
    }
    
    .flexRow {
        max-width: 1200px;
    }
    
    .flexCol {
        overflow-y: scroll;
    }

    .q {
        width: 280px;
    }
   
}
