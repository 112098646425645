@font-face {
    font-family: Kusanagi;
    font-display: swap;
    src: url(./fonts/Kusanagi/Kusanagi.otf);
}

@font-face {
    font-family: Roboto;
    font-display: swap;
    src: url(./fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
    font-family: Aceh;
    font-display: swap;
    src: url(./fonts/Aceh/Aceh-Light.ttf);
}
@font-face {
    font-family: Israr-Syria;
    font-display: swap;
    src: url(./fonts/Israr-Syria/Israr-Syria.otf);
}
@font-face {
    font-family: SwissraM;
    font-display: swap;
    src: url(./fonts/Swissra/Swissra_Light_Mobile.woff);
}
@font-face {
    font-family: SwissraD;
    font-display: swap;
    src: url(./fonts/Swissra/Swissra_Medium_Desktop.woff);
}