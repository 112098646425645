
.slick-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

  .slideWrapper {
    display: flex;
    justify-content: center;
  }
  
  .prevArrow {
    position: absolute;
    top: 40%;
    left: 60px;
    z-index: 100;
    cursor: pointer;
    font-size: 2rem;
  }
  
  .nextArrow {
    position: absolute;
    top: 40%;
    right: 60px;
    z-index: 100;
    cursor: pointer;
    font-size: 2rem;
  }
  /* 
  .prevArrow img,
  .nextArrow img {
    width: 4rem;
  } */
  