.backdrop {
    clip-path: 
    polygon(
      0% 20px,                 /* top left */
      0px 0%,                 /* top left */
      calc(100% - 20px) 0%,    /* top right */
      100% 20px,               /* top right */
      100% calc(100% - 20px),  /* bottom right */
      calc(100% - 20px) 100%,  /* bottom right */
      0px 100%,               /* bottom left */
      0 calc(100% - 20px)      /* bottom left */
    );
    padding: 20px;
    background-color: rgba( 0, 0, 0, 0.5);
}